import React from 'react'
import Events from '../components/events/events'

const EventsPage = () => {
  return (
    <>
    <Events/>
    </>
  )
}

export default EventsPage
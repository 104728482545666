export const update20240118 = {
    title: `Box of Trouble Update`,
    content:  `1200 words last night. Mostly dialogue. Will need to go back and insert some description beats. 
    <br/><br/>
    That's how you eat an elephant. One bite at a time.
    `,
    link: 'https://twitter.com/Devon_Eriksen_/status/1747989900933083150',
    date: '2024-01-18',
    type: 'twitter',
    tag: 'updates',
  };
import React from 'react'
import Biography from '../components/AuthorCard/Biography'


const About = () => {
  return (
    <>
      <Biography />
    </>
  )
}

export default About
